<script>
  import { flatten } from 'lodash-es';

  export default {
    name: 'BatchEditListDialog',
    data() {
      return {
        inputValue: '',
      };
    },
    model: {
      prop: 'value',
      event: 'input',
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      value: {
        type: Array,
        required: true,
        default: () => [],
      },
      visible: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: '请输入',
      },
      inputAutoSize: {
        type: [Object, Boolean],
        default: true,
      },
      inputProps: {
        type: Object,
        default: () => ({}),
      },
      options: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      getDialogProps() {
        return {
          title: this.title,
        };
      },
      filterOptions() {
        const values = this.inputValue.split(',');
        const _options = this.options.map((item) => {
          return {
            ...item,
            children: item.children.filter((item) => {
              return values.includes(item.value.toString());
            }),
          };
        });
        return _options.filter((item) => {
          return item.children.length > 0;
        });
      },
      hasOptions() {
        return this.filterOptions.length > 0;
      },
    },
    methods: {
      closeDialog() {
        this.$emit('update:visible', false);
      },
      comfirmDialog() {
        const values = this.filterOptions.map((item) => {
          return item.children.map((item) => item.value);
        });
        this.$emit('input', flatten(values));
        this.closeDialog();
      },
    },
    watch: {
      visible(_visible) {
        if (_visible) {
          this.inputValue = this.value.join(',');
        }
      },
    },
  };
</script>

<template>
  <el-dialog
    :visible="visible"
    align-center
    v-bind="getDialogProps"
    class="batch-edit-list__dialog "
  >
    <el-input
      class="batch-edit-list__textarea"
      v-model="inputValue"
      type="textarea"
      :placeholder="placeholder"
      :autosize="inputAutoSize"
      v-bind="inputProps"
    />
    <el-card class="batch-edit-list__list">
      <div class="batch-edit-list__list--list" v-show="hasOptions">
        <div v-for="(group, ind) in filterOptions" :key="ind" class="batch-edit-list__list--group">
          <div class="batch-edit-list__list--group-name">
            {{ group.label }}
          </div>
          <div v-for="item in group.children" :key="item.value" class="batch-edit-list__list--item">
            {{ item.label }}
          </div>
        </div>
      </div>
      <el-empty v-show="!hasOptions" :image-size="50" />
    </el-card>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="comfirmDialog">
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
  .batch-edit-list {
    &__textarea {
      margin-bottom: 24px;
    }

    &__list {
      &--group {
        &-name {
          font-weight: bold;
        }
      }

      &--list {
        max-height: 200px;
        overflow-y: auto;
      }

      &--item {
        line-height: 28px;
        height: 28px;
      }
    }
  }
</style>
