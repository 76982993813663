<template>
  <div class="group-select-wrapper">
    <el-select
      ref="fuzzySearch"
      :value="selectValue"
      @input="changeValue"
      :size="size"
      :loading="loading"
      :loading-text="loadingText"
      :reserve-keyword="reserveKeyword"
      :multiple="multiple"
      :collapseTags="collapseTags"
      :clearable="clearable"
      :filterable="filterable"
      @change="handleChange"
      @clear="handleClear"
      :filter-method="handleFilter"
      @focus="focusSelectValue"
      :disabled="disabled"
    >
      <el-option-group v-for="group in options" :key="group[parentKey]" :label="group[labelProp]">
        <el-checkbox
          v-if="hasAll && multiple && group[children].length > 0"
          v-model="group.checked"
          class="ml15"
          @change="selectAll($event, group[parentKey])"
        >
          全选
        </el-checkbox>
        <el-option
          v-for="item in group[children]"
          :key="item[valueProp]"
          :label="item[labelProp]"
          :value="item[valueProp]"
        >
          <slot name="option-item" :scope="item"></slot>
        </el-option>
      </el-option-group>
    </el-select>
    <template v-if="showBatch">
      <el-button
        class="group-select--btn"
        type="text"
        @click="dialogVisible = true"
        :disabled="disabled"
        icon="el-icon-edit-outline"
      />
      <batch-edit-list-dialog
        title="Batch Setting"
        :visible.sync="dialogVisible"
        :placeholder="editPlaceholder"
        :value="selectValue"
        :input-auto-size="{
          minRows: 1,
          maxRows: 3,
        }"
        :options="options"
        @input="changeValue"
      />
    </template>
  </div>
</template>

<script>
  import { cloneDeep } from 'lodash-es';
  import BatchEditListDialog from '@/components/BatchEditListDialog/index.vue';

  export default {
    name: 'GroupSelect',
    components: { BatchEditListDialog },
    model: {
      prop: 'selectValue',
      event: 'input',
    },
    data() {
      return {
        options: [],
        searchVal: '',
        dialogVisible: false,
      };
    },
    props: {
      editPlaceholder: {
        type: String,
        default: '请输入',
      },
      showBatch: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      selectValue: {
        required: true,
      },
      dataSource: {
        type: Array,
        default: () => [],
      },
      parentKey: {
        type: String,
        default: 'id',
      },
      labelProp: {
        type: String,
        default: 'label',
      },
      valueProp: {
        type: String,
        default: 'value',
      },
      children: {
        type: String,
        default: 'children',
      },
      hasAll: {
        type: Boolean,
        default: true,
      },
      size: {
        type: String,
        default: '',
      },
      reserveKeyword: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      loadingText: {
        type: String,
        default: '加载中',
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      collapseTags: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      filterable: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      this.options = cloneDeep(this.dataSource);
    },
    methods: {
      changeValue(value) {
        this.$emit('input', value);
      },
      selectAll(val, id) {
        const curArr = this.options.find((f) => f.id === id);
        const arr = curArr[this.children].map((m) => m[this.valueProp]);
        if (val) {
          arr.forEach((item) => {
            if (!this.selectValue.includes(item)) {
              this.selectValue.push(item);
            }
          });
        } else {
          this.selectValue.forEach((item, index) => {
            if (arr.includes(item)) {
              this.selectValue.splice(index, 1, '');
            }
          });
        }

        this.$emit(
          'input',
          this.selectValue.filter((f) => f !== '')
        );
        this.$emit(
          'change',
          this.selectValue.filter((f) => f !== '')
        );
      },
      handleFilter(val) {
        this.searchVal = val;
        if (val) {
          const result = this.dataSource.reduce((accumulator, curVal) => {
            const item = cloneDeep(curVal);
            if (!curVal.children) {
              return accumulator;
            }
            const children = item.children.filter((item) => {
              if (
                !!~item.label.indexOf(val) ||
                !!~item.label.toUpperCase().indexOf(val.toUpperCase())
              ) {
                return true;
              }
            });
            item.children = children;
            item.checked = children.every((v) => {
              return Array.isArray(this.selectValue)
                ? this.selectValue?.some((s) => s === v[this.valueProp])
                : false;
            });
            accumulator.push(item);
            return accumulator;
          }, []);
          this.options = result;
        } else {
          this.options = cloneDeep(this.dataSource);
        }
      },

      handleChange(val) {
        if (this.multiple && this.hasAll) {
          this.options.forEach((item) => {
            const arr = item[this.children].map((m) => m[this.valueProp]);
            item.checked =
              arr.length > 0 &&
              arr.every((v) => {
                return val.some((s) => s === v);
              });
          });
        }

        this.$emit('change', val);
      },
      handleClear() {
        this.options = cloneDeep(this.dataSource);
      },
      focusSelectValue() {
        if (this.$refs.fuzzySearch.$refs.input) {
          this.$refs.fuzzySearch.$refs.input.blur = () => {
            this.handleClear();
          };
        }
      },
    },
    watch: {
      searchVal(val) {
        if (!val) {
          this.options = cloneDeep(this.dataSource);
        }
      },
      dataSource(val) {
        this.options = cloneDeep(val);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .ml15 {
    margin-left: 18px;
  }

  .group-select {
    &-wrapper {
      display: flex;
    }

    &--btn {
      margin-left: 8px;
    }
  }

  ::v-deep .el-select__tags {
    width: 100%;
    max-height: 30px;
    overflow-y: auto;
    overflow-x: visible;
    margin-left: 5px;

    &::-webkit-scrollbar {
      width: 2px;
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: #dcdfe6;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: #c0c4cc;
    }
  }
</style>
